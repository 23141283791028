import React from 'react';

export default class SubmitConfirmEmail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			response_success_message: false,
		};
	}

	componentDidMount() {
		this.$form.form2('setup');
		this.$form.form2('submit', {
			method: 'POST',
			url: process.env.REACT_APP_HOST_URL2 + '/api/v1/email_confirm',
			beforeSent: (object) => {
				return object;
			},
			loadingButton: this.$form.find('button.button.approve'),
			onSuccess: (response) => {
				this.setState({
					response_success_message: response.message,
				});
				// let _redirect = setTimeout(() => {
				//    window.location = '/mine?confirm-email=1';
				//    clearTimeout(_redirect);
				// }, 5000);
			},
			onError: (response) => {
				this.$form.find('.info.message').hide();
			}
		});
		this.$form.find('button.button.approve').trigger('submit');
	}

	render() {
		return (
			<>
				<div className="ui container"
				     ref={(ref) => {
					     this.$content = window.$(ref);
				     }}>
					<div className="flex row wrap center-xs middle-xs">
						<div className="ui segment col-xs-16 col-lg-10">
							{
								this.state.response_success_message
									? <div className="ui success icon message">
										<i className="check icon"/>
										<div className="content">
											<h3 className="ui header" style={{textAlign: 'left'}}>
												{this.state.response_success_message}
											</h3>
										</div>
									</div>
									: null
							}
							{
								!this.state.response_success_message
									?
									<form className={"ui form"}
									      ref={(ref) => {
										      if (this.$form) return;
										      this.$form = window.$(ref);
									      }}
									>
										<h3 className={"ui header"}><i className="icon inbox"/>Email Confirmation</h3>
										<div className="ui info icon message">
											<i className="notched circle loading icon"/>
											<div className="content">
												<h3 className="ui header" style={{textAlign: 'left'}}>
													Email Confirmation is processing่...
												</h3>
											</div>
										</div>
										<input type={"hidden"}
										       name={"token"}
										       value={window.Global.getAllQueryStrings().t}
										/>
										<div className="field GoogleRecaptcha_root"/>
										<div className="ui error message"
										     style={{marginBottom: 0}}
										/>
										<button type={"submit"}
										        style={{display: 'none'}}
										        className={"ui button blue approve"}>
											Submit
										</button>
									</form>
									: null
							}
						</div>
					</div>
				</div>
			</>
		);
	}
}
