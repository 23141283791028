import React from 'react';
import {Link} from "react-router-dom";

export default class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			qs: window.Global.getAllQueryStrings(),
		};
	}

	componentDidMount() {

	}

	render() {
		return (
			<div>
				<div className="secondBackground"
				     style={{
					     position: 'relative',
				     }}>
					{/*<div className="banner1"/>*/}
					<div className="flex row wrap padded"
					     style={{
						     padding: '3rem 0'
					     }}
					>
						<div className="col-lg-8 col-xs-16 padded"
						     style={{
							     zIndex: 100
						     }}>
							<img src="images/HCGoodsPro.jpg"
							     alt=""
							     style={{borderRadius: '1rem'}}
							/>
						</div>
						<div className="col-lg-8 col-xs-16 padded"
						     style={{
							     padding: '3rem',
							     zIndex: 100
						     }}>
							<div>
								<h1 style={{fontSize: '3.5rem'}}>
									The Crypto Mining is complex.
									<br/>We make it simple.
									{/*ทำเหมืองคริปโตแบบไร้ความกังวล*/}
								</h1>
								<div style={{fontSize: '2rem', lineHeight: 1.3, padding: '2rem 0 0 0'}}>
									Hcgoods Software monitor health of mining machine. Predict and prevent machine
									problem. Configuration hundreds machine in one click. Scale up with confidence. Mining cryptocurrency at full capacity, and you’ll love.
									{/*ด้วยโปรแกรม Hcgoods จะช่วยให้คุณสามารถติดตามสถานะที่จำเป็นของเครื่องขุดเหรียญคริปโตของคุณเป็นไปอย่างราบรื่น*/}
									{/*ป้องกันปันหาต่างๆ ได้ล่วงหน้า และยังลดปันหาการ Config ที่ยุ่งยาก*/}
								</div>
							</div>
							<div className="flex row wrap"
							     style={{margin: '4rem 0 0 0'}}
							>
								{/*<Link to={"/"} className="ui button circular basic massive">Price</Link>*/}
								<Link to={"/register"} className="ui button circular black massive">Register</Link>
							</div>
						</div>
					</div>
					<div className="ui container padded">
						<div className="ui box flex row wrap padded middle-xs"
						     style={{width: '100%', padding: '2rem'}}>
							<div className="col-lg-8 col-xs-16 padded">
								<h1 className="color blue"><i className="bullhorn icon"></i>&nbsp;&nbsp;
									<span>Free trial</span> 30 Days</h1>
								<div className="ui info message"
								     style={{margin: '0.1rem 0 0 0', fontSize: '1rem'}}>
									<b>F5R1ZAXEBSLPRF0DBWNUTGY</b>
									&nbsp;<span className="link"
									            onClick={() => {
										            if (navigator.clipboard) {
											            window.Global.messageTop({
												            type: 'positive',
												            message: 'Copied'
											            })
											            navigator.clipboard.writeText('F5R1ZAXEBSLPRF0DBWNUTGY');
										            }
									            }}>
											<b>Copy</b>
									</span>
								</div>
								<div style={{margin: '0.8rem 0 0 0'}}>This licence code valid until 31 Jan 2022</div>
								<h1 className="color blue">Pricing</h1>
								<div>Start at <b>0.15 USDT</b> per day</div>
							</div>
							<div className="col-lg-8 col-xs-16 padded flex center-xs">
								<div>
									<div style={{lineHeight: 2}}>
										<a href="/assets/hcgoods-v1.zip" className="ui button massive circular black"
										   target={"_blank"}><i className="cloud download icon"></i> Download Software Setup</a>
										<br/>
										<span style={{color: '#898989'}}>Version 1 · 01/01/2022</span>
									</div>
									<div style={{margin: '1rem 0 0 0'}}>
										<a href="/assets/HCGoods-Manual.pdf" className="ui button massive circular basic"
										   target={"_blank"}><i className="info circle icon"></i> User Manual</a>
									</div>
								</div>
							</div>
						</div>
						<br/>
						<br/>
						<br/>
						<div className="ui box flex row wrap middle-xs"
						     style={{width: '100%', padding: '1.5rem', textAlign: 'center'}}>
							<div className="col-xs-16">
								<h1 style={{marginBottom: '2rem'}}><span className="color blue">Customer</span></h1>
								<div className="flex row wrap padded center=xs middle-xs">
									<div className="col-lg-4 col-xs-16 padded">
										<img src="/images/customer/1.jpg" alt="" className="ui image"/>
									</div>
									<div className="col-lg-4 col-xs-16 padded">
										<img src="/images/customer/2.jpg" alt="" className="ui image"/>
									</div>
									<div className="col-lg-4 col-xs-16 padded">
										<img src="/images/customer/3.jpg" alt="" className="ui image"/>
									</div>
									<div className="col-lg-4 col-xs-16 padded">
										<img src="/images/customer/4.jpg" alt="" className="ui image"/>
									</div>
									<div className="col-lg-4 col-xs-16 padded" style={{marginTop: '1rem'}}>
										<img src="/images/customer/5.jpg" alt="" className="ui image"/>
									</div>
									<div className="col-lg-4 col-xs-16 padded" style={{marginTop: '1rem'}}>
										<img src="/images/customer/6.jpg" alt="" className="ui image"/>
									</div>
									<div className="col-lg-4 col-xs-16 padded" style={{marginTop: '1rem'}}>
										<img src="/images/customer/7.jpg" alt="" className="ui image"/>
									</div>
									<div className="col-lg-4 col-xs-16 padded" style={{marginTop: '1rem'}}>
										<img src="/images/customer/8.jpg" alt="" className="ui image"/>
									</div>
								</div>
							</div>
						</div>
						<br/>
						<br/>
						<h1 className="color blue" style={{textAlign: 'center'}}>See what <u>Hcgoods Software</u> can help</h1>
						<br/>
						<div className="flex row wrap padded center-xs middle-xs">
							<div className="col-lg-4 col-xs-16 padded">
								<div className="ui box">
									<h3>Easy, convenient and fast. Configuration hundreds machine in one click</h3>
								</div>
							</div>
							<div className="col-lg-4 col-xs-16 padded center-xs">
								<div className="ui box">
									<h3>Detect and monitor health of mining machine such as Bad card, low Hash Rate, broken fan,
										etc</h3>
								</div>
							</div>
							<div className="col-lg-4 col-xs-16 padded center-xs">
								<div className="ui box">
									<h3>Time-saving, cost-effective, no wasted mining opportunities. The mining is fully
										working</h3>
								</div>
							</div>
							<div className="col-lg-4 col-xs-16 padded center-xs">
								<div className="ui box">
									<h3>Display screen for easy viewing. There is a color bar indicating the symptoms</h3>
								</div>
							</div>
							<div className="col-lg-4 col-xs-16 padded center-xs" style={{marginTop: '1rem'}}>
								<div className="ui box">
									<h3>Can control and create automate task without human errors. Support Line notify Alert</h3>
								</div>
							</div>
							<div className="col-lg-4 col-xs-16 padded center-xs" style={{marginTop: '1rem'}}>
								<div className="ui box">
									<a href="/assets/HCGoodsSystem_GaneralSystem.pdf"
									   className=""
									   target={"_blank"}>
										<h3>Read a full comparison</h3>
									</a>
								</div>
							</div>
						</div>
						<br/>
						<br/>
						<br/>
						<div className="ui box flex row wrap middle-xs"
						     style={{width: '100%', padding: '1.5rem', textAlign: 'center'}}>
							<div className="col-lg-8 col-xs-16">
								<h1><span className="color blue"><i className="bullhorn icon"></i>&nbsp;Contact Us</span></h1>
								<h2 style={{margin: '0.8rem 0 0 0', fontWeight: 100}}>hcgoods.contact@gmail.com</h2>
							</div>
							<div className="col-lg-8 col-xs-16">
								<Link to={"/register"} className="ui button circular black massive">Get free trial , Register
									now</Link>
							</div>
						</div>
					</div>
					<br/>
					<br/>
					<br/>
					<br/>
				</div>
			</div>
		)
	}
}
