import './App.css';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from "react-router-dom";
import React from 'react';
import Register from "./Register";
import SubmitConfirmEmail from "./SubmitConfirmEmail";
import MyProfile from "./MyProfile";
import Home from "./Home";
import Mine from "./Mine";

window.Global2 = {
	getMyProfile: () => {
		// let token = window.Global.getCookie('token');
		//user_id
		window.Global.ajax({
			method: 'GET',
			url: process.env.REACT_APP_HOST_URL2 + '/api/v1/user',
			data: {
				user_id: 'me',
				skipRedirect: true
			},
			done: (response) => {
				if (!response.success) {
					return false;
				}
				window.login_user = response.user[0];
			}
		});
		return false;
	},
	loginCheck: (url) => {
		if (!window.Global2.getMyProfile().id) {
			window.Global.messageTop({
				type: 'negative',
				message: "โปรดเข้าสู่ระบบเพื่อเข้าใช้งาน",
			});
			return false;
		}
		window.location.href = url;
	},
	getQuantity: (sale_order_item) => {
		if (sale_order_item.sale_order_item_product_display_link._quantity) {
			return sale_order_item.sale_order_item_product_display_link._quantity.format(2, 3, {pretty: true}).toString()
		}
		return sale_order_item.quantity2;
	},
};

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			qs: window.Global.getAllQueryStrings(),
		};
	}

	componentDidMount() {
		if (
			!localStorage.getItem('token_for_development')
			|| (
				window.location.hostname !== 'localhost'
				&& !window.Global.getCookie('token')
			)
		) {
			this.setState(
				{
					loading: false,
				}
			);
			return;
		}

		if (
			localStorage.getItem('token_for_development')
			&& (
				window.location.pathname === '/signout'
			)
		) {
			window.Global.createCookie(
				'token',
				'',
				-365
			);
			localStorage.setItem('token_for_development', '');
			window.location.href = '/';
			return;
		}

		window.Global.ajax({
			method: 'GET',
			url: process.env.REACT_APP_HOST_URL2 + '/api/v1/user',
			data: {
				user_id: 'me'
			},
			done: (response) => {
				if (!response.success) {
					return false;
				}
				window.login_user = response.user[0];

				this.setState(
					{
						loading: false,
						login_user: window.login_user,
					}
				);
			}
		});
	}

	render() {
		if (this.state.loading) {
			return null;
		}
		return (
			<Router>
				<div className="App flex">
					<header className="App-header">
						<div className="ui container form" style={{padding: 0}}>
							<div className="flex row middle-xs wrap">
								<Link to={'/'}
								      style={{
									      color: 'gold',
									      fontFamily: "'Zen Dots', cursive",
									      fontWeight: "bold",
									      letterSpacing: "1px",
								      }}
								      className="logo">
									<img src="/images/logo.png" alt="" style={{width: '10rem'}}/>
								</Link>
								<div className="right floated">
									<>
										<Link to="/">Solutions</Link>
									</>
									<>
										<Link to="/">Price</Link>
									</>
									<>
										<Link to="/">Customers</Link>
									</>
									<>
										<Link to="/">Privacy</Link>
									</>
									{
										!this.state.login_user
											?
											<>
												<Link to="/login">Login</Link>
												<Link to="/register"
												      className="ui button large circular basic black"
												      style={{color: '#005ed1'}}
												>
													<b>Register</b>
												</Link>
											</>
											:
											<>
												<a href="/"
												   onClick={(e) => {
													   e.preventDefault();
													   window.Global.createCookie(
														   'token',
														   '',
														   365
													   );
													   window.localStorage.setItem('token_for_development', '');
													   window.location.href = '/';
												   }}>
													Logout
												</a>
											</>
									}
									{
										this.state.login_user
										&&
										<Link to="/mine"
										      className="ui button large circular basic black"
										      style={{color: '#005ed1', textTransform: 'capitalize'}}
										>
											<b>{this.state.login_user.full_name.substr(0, 15)}</b>
										</Link>
									}
								</div>
								{/*<div className="ui left icon input SearchHeader right floated col-lg-4">*/}
								{/*	<i className="search icon"/>*/}
								{/*	<input key={window.Global.getRandomInt(1, 10000000) + 'ddd'}*/}
								{/*	       type="text"*/}
								{/*	       style={{background: '#ffffff4a', color: '#FFF'}}*/}
								{/*	       placeholder="ค้นหา"*/}
								{/*	       name="search"*/}
								{/*	       defaultValue={window.Global.getAllQueryStrings().search}*/}
								{/*	       onKeyUp={(e) => {*/}
								{/*		       if (e.keyCode === 13) {*/}
								{/*			       window.location = '/?search=' + e.target.value;*/}
								{/*		       }*/}
								{/*	       }}*/}
								{/*	/>*/}
								{/*</div>*/}
							</div>
						</div>
					</header>
					<div className="flex"
					     style={{
						     height: '100%',
						     flex: 'auto',
					     }}>
						<Routes>
							<Route path="/register" element={<Register mode={'register'}/>}/>
							<Route path="/login" element={<Register mode={'login'}/>}/>
							<Route path="/signout" element={<Register mode={'signout'}/>}/>
							<Route path="/forgot-password" element={<Register mode={'forgot-password'}/>}/>
							<Route path="/password-reset" element={<Register mode={'submit-password-reset'}/>}/>
							<Route path="/confirm-email" element={<SubmitConfirmEmail/>}/>
							<Route path="/my-profile" element={<MyProfile mode={'view'}/>}/>
							<Route path="/" element={<Home/>}/>
							<Route path="/mine/edit" element={<Mine mode="edit"/>}/>
							<Route path="/mine" element={<Mine mode={'list'}/>}/>
							<Route path="/mine/create" element={<Mine mode="create"/>}/>
							<Route path="/confirm_email" element={<SubmitConfirmEmail mode="create"/>}/>
							{/*<Route path="/product/:product_display_id/edit" component={ProductDisplay}/>*/}
							{/*<Route path="/checkout">*/}
							{/*  <Cart mode="list"/>*/}
							{/*</Route>*/}
							{/*<Route path="/history">*/}
							{/*  <SaleOrderViaECommerce mode="list"/>*/}
							{/*</Route>*/}
							{/*<Route path="/">*/}
							{/*  <ProductDisplay mode="list"/>*/}
							{/*</Route>*/}
						</Routes>
					</div>
					<footer style={{
						padding: '1rem',
						borderTop: '1px solid #e6e6e624',
						color: '#ffffff78',
						textAlign: 'center',
						background: '#282c34',
					}}>
						<div>Copyright © 2021 HCGoods · hcgoods.contact@gmail.com</div>
						{/*<div style={{fontSize: '0.9rem'}}>การลงทุนมีความเสี่ยง ผู้ลงทุนควรทำความเข้าใจลักษณะสินค้า*/}
						{/*	เงื่อนไขผลตอบแทนและความเสี่ยงก่อนตัดสินใจลงทุน ผลการดำเนินงานในอดีต*/}
						{/*	มิได้เป็นสิ่งยืนยันถึงผลการดำเนินงานในอนาคต*/}
						{/*</div>*/}
					</footer>
				</div>
			</Router>
		);
	}
}

export default App;
