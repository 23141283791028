import React from 'react';
import {Link} from "react-router-dom";
import {Form2FileUpload} from "react-spaceship-web";

export default class Mine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			qs: window.Global.getAllQueryStrings(),
			isLoading: true,
			licences: [],
			list_referrals: [],
		};
	}

	componentDidMount() {
		if (this.props.mode
			&& this.props.mode === 'list'
		) {
			window.Global.ajax({
				method: 'GET',
				url: process.env.REACT_APP_HOST_URL2 + '/api/v1/licence',
				data: {},
				done: (response) => {
					if (!response.success) {
						return;
					}
					this.setState({
						licences: response.licence,
						isLoading: false
					});
				}
			});
			window.Global.ajax({
				method: 'GET',
				url: process.env.REACT_APP_HOST_URL2 + '/api/v1/user',
				data: {
					type: 'getReferral'
				},
				done: (response) => {
					if (!response.success) {
						return;
					}
					this.setState({
						list_referrals: response.user
					});
				}
			});
		}

		if (this.props.mode
			&& this.props.mode === 'edit'
		) {
			window.Global.ajax({
				method: 'GET',
				url: process.env.REACT_APP_HOST_URL2 + '/api/v1/licence',
				data: {
					licence_id: this.state.qs.licence_id
				},
				done: (response) => {
					if (!response.success) {
						return;
					}
					this.setState({
						licence: response.licence[0],
						licence_history: response.licence[0].licence_history.filter((licence_history) => {
							return licence_history.id === this.state.qs.licence_history_id
						})[0],
						isLoading: false
					});
				}
			});
		}
	}

	render() {
		if (this.props.mode
			&& this.props.mode === 'create'
		) {
			return this.renderCreate();
		}

		if (this.props.mode
			&& this.props.mode === 'edit'
		) {
			return this.renderEdit();
		}

		return (
			<div className="secondBackground fluid"
			     style={{
				     position: 'relative',
				     backgroundSize: '100%',
				     backgroundImage: 'linear-gradient(#fff, #ccc)',
			     }}>
				{/*<div className="banner1"/>*/}
				<div className="ui container padded">
					<div className="flex row middle-xs center-xs">
						<h2 style={{margin: 0}}>My Licences</h2>
						<div className="right floated">
							<a href="/assets/hcgoods-v1.zip" className="ui button large circular basic black"
							   target={"_blank"}>Download Software Setup (V.1)</a>
							<Link to="/mine/create" className="ui button large circular basic black"
							      style={{margin: '0 0 0 2rem'}}>Buy Licence</Link>
						</div>
					</div>
					<div className="ui message brown">
						<b>Download HCGoods Software <a href="/assets/hcgoods-v1.zip" className="link" target={"_blank"}>Click
							here</a> and start by use free licence code <span
							style={{color: '#000'}}>F5R1ZAXEBSLPRF0DBWNUTGY</span> <span className="link"
						                                                                onClick={() => {
							                                                                if (navigator.clipboard) {
								                                                                window.Global.messageTop({
									                                                                type: 'positive',
									                                                                message: 'Copied'
								                                                                })
								                                                                navigator.clipboard.writeText('F5R1ZAXEBSLPRF0DBWNUTGY');
							                                                                }
						                                                                }}>
														<b>Copy</b>
													</span></b>
					</div>
					<br/>
					<div className="flex row wrap padded three column computer">
						{
							this.state.licences.length !== 0
							&&
							this.state.licences.map((licence, i) => {
								return (
									<div key={i}
									     className="column">
										<div className="ui box">
											<div className="flex row center-xs middle-xs">
												<img src="images/server-icon.svg"
												     style={{width: '5rem'}}
												     alt="Kiwi standing on oval"
												/>
												{
													licence.active_licence_history
													&&
													<>
														&nbsp;<span style={{fontSize: '1.5rem'}}><b>x</b></span>&nbsp;&nbsp;&nbsp;
														<span style={{fontSize: '2rem'}}>
															<b>{licence.active_licence_history.number_of_server.format()}</b>
														</span>
													</>
												}
											</div>
											<div className="flex row between-xs" style={{fontSize: '1.3rem'}}>
												<div><b>{licence.name}</b></div>
											</div>
											{
												licence.licence_history.length
												&& licence.active_licence_history
												&&
												<div style={{margin: '1rem 0 0 0'}}>
													<div style={{fontSize: '1rem'}}><b>Expire date</b></div>
													<div>
														<b style={{color: '#0080d7'}}>{window.moment(licence.active_licence_history.end_date).format('D MMMM YYYY')}</b>
													</div>
													<div style={{fontSize: '1rem', margin: '1rem 0 0 0'}}><b>Activation Code</b>
													</div>
													<div className="ui label blue basic"
													     style={{margin: '0.1rem 0 0 0', fontSize: '1rem'}}>
														{licence.code}&nbsp;&nbsp;
														<span className="link"
														      onClick={() => {
															      if (navigator.clipboard) {
																      window.Global.messageTop({
																	      type: 'positive',
																	      message: 'Copied'
																      })
																      navigator.clipboard.writeText(licence.code);
															      }
														      }}>
														<b>Copy</b>
													</span>
													</div>
												</div>
											}
											{
												licence.licence_history.length
												&& licence.licence_history[0].is_paid === null
												&&
												<>
													<div style={{fontSize: '1.1rem', margin: '1rem 0 0.3rem 0'}}><b>Status</b></div>
													{
														window.login_user.is_admin
															?
															<a
																href={'/mine/edit?licence_id=' + licence.id + '&licence_history_id=' + licence.licence_history[0].id}
																className="ui label blue">
																Waiting approve payment
															</a>
															:
															<div className="ui label blue">
																Waiting approve payment
															</div>
													}
												</>
											}
											{
												licence.licence_history.map((licence_history, i) => {
													return (
														<div key={i}>
															{
																window.login_user.is_admin
																&&
																<div className="ui box"
																     style={{margin: '1rem 0 0 0'}}>
																	<div>
																		<b>Created
																			At</b> {window.moment(licence_history.created_at).format('D MMMM YYYY')}
																	</div>
																	<div>
																		<b>Number of
																			Servers</b> {parseInt(licence_history.number_of_server)} * {parseInt(licence_history.number_of_day)} Days
																	</div>
																	<div>
																		<b>From Address</b> {licence_history.from_address}
																	</div>
																	<div>
																		<b>Remark</b> {licence_history.remark}
																	</div>
																	<div>
																		<b>Date</b> {window.moment(licence_history.start_date).format('D MMMM YYYY')} - {window.moment(licence_history.end_date).format('D MMMM YYYY')}
																	</div>
																	<div>
																		<b>Status</b> {licence_history.is_paid}
																	</div>
																	<a
																		href={'/mine/edit?licence_id=' + licence.id + '&licence_history_id=' + licence_history.id}
																		className="link">
																		Edit
																	</a>
																</div>
															}
														</div>
													)
												})
											}
											{
												<div>
													<br/>
													<a
														href={'/mine/renew?licence_id=' + licence.id}
														className="ui button fluid small basic blue">
														Renew Licence
													</a>
												</div>
											}
										</div>
									</div>
								)
							})
						}
					</div>
					{
						this.state.licences.length === 0
						&&
						<div style={{textAlign: 'center'}}>
							<div className="flex row center-xs">
								<img src="/images/server-icon.svg"
								     style={{width: '5rem'}}
								     alt="Kiwi standing on oval"
								/>
							</div>
							{/*<Link to="/mine/create"*/}
							{/*      style={{marginTop: '0.5rem'}}*/}
							{/*      className="ui button large circular basic black">Buy Licence</Link>*/}
						</div>
					}
					<h2>Profile</h2>
					<div className="ui box" style={{lineHeight: 2}}>
						<b>Name</b> <span style={{textTransform: 'capitalize'}}>{window.login_user.full_name}</span>
						<br/><b>Email</b> <span>{window.login_user.email}</span>&nbsp;
						{
							window.login_user.is_verify_email
								? <span className="ui label green basic tiny">Verified</span>
								: <span className="ui label yellow basic tiny">Unverified Email</span>
						}
						<br/><b>Mobile phone number</b> <span>(+{window.login_user.phone_number_country_code})</span>
						&nbsp;<span>{window.login_user.phone_number}</span>
						{
							window.login_user.referral_link
							&&
							<>
								<br/><b>Referral Link</b> <span className="link"
								                                onClick={() => {
									                                if (navigator.clipboard) {
										                                window.Global.messageTop({
											                                type: 'positive',
											                                message: 'Link Copied'
										                                })
										                                navigator.clipboard.writeText(window.login_user.referral_link);
									                                }
								                                }}>{window.login_user.referral_link}</span>&nbsp;&nbsp;
								<span className="link"
								      onClick={() => {
									      if (navigator.clipboard) {
										      window.Global.messageTop({
											      type: 'positive',
											      message: 'Link Copied'
										      })
										      navigator.clipboard.writeText(window.login_user.referral_link);
									      }
								      }}>
							<b>Copy</b>
						</span>
							</>
						}
					</div>
					{
						window.login_user.referral_link
						&&
						<>
							<h2>Your Referral</h2>
							{
								this.state.list_referrals.length === 0
								&&
								<div className="ui box">
									<div className="flex row wrap">
										<span>Start invite your friend</span>
									</div>
								</div>
							}
							<div>
								{
									this.state.list_referrals.length !== 0
									&&
									this.state.list_referrals.map((list_referral_user) => {
										return (
											<div className="ui box">
												<div className="flex row wrap">
													<b>{list_referral_user.full_name}</b>&nbsp;
													<span>({list_referral_user.email})</span>
												</div>
											</div>
										)
									})
								}
							</div>
						</>
					}
				</div>
			</div>
		)
	}

	calculatePrice() {
		if (this.$form.find('input[name="number_of_server"]').length === 0) {
			return;
		}
		let number_of_server = this.$form.find('input[name="number_of_server"]').cleanNumber({
			decimal: false,
			allow_negative: false
		});
		let number_of_day = this.$form.find('input[name="number_of_day"]').cleanNumber({
			decimal: false,
			allow_negative: false
		});
		let price = 0.15;

		if (window.login_user.is_free_try) {
			price = 0;
		}

		let sum = number_of_server * number_of_day * price;

		this.$form.find('#summary').html(
			window.Handlebars.compile('<div style="border-radius: 6px;\n    border: 1px solid #4b91c0;\n    padding: 0.5rem;">\n\t<b>{{number_of_server}}</b> Servers\n\t<br><b>{{number_of_day}}</b> Days * <b>{{price}}</b> USDT\n\t<br>Total <b>{{sum}}</b> USDT\n</div>')({
				number_of_server: number_of_server.format(),
				number_of_day: number_of_day.format(),
				price: price.format(4),
				sum: sum.format(2),
			})
		)

		if (this.props.mode
			&& this.props.mode === 'edit'
		) {
			this.$form.find('.button.approve').text(
				'Save'
			)
		} else {
			this.$form.find('.button.approve').text(
				'Submit Order · ' + sum.format(2) + ' USDT'
			)
		}
	}

	renderCreate() {
		return (
			<div className="secondBackground fluid">
				<form className="ui container padded form"
				      autoComplete="off"
				      ref={(ref) => {
					      this.$form = window.$(ref);
					      this.$form.form2('submit', {
						      method: 'POST',
						      url: process.env.REACT_APP_HOST_URL2 + '/api/v1/licence',
						      beforeSent: (object) => {
							      return object;
						      },
						      loadingButton: this.$form.find('.button.approve'),
						      submitButton: this.$form.find('.button.approve'),
						      onSuccess: (response) => {
							      window.Global.messageTop({
								      type: 'positive',
								      message: response.message,
								      delay: 10000,
							      });
							      window.location.href = '/mine';
						      }
					      });

					      setTimeout(() => {
						      this.calculatePrice();
					      }, 400)
				      }}>
					<div className="flex row">
						<a href="/mine" className="ui button large circular basic black">Cancel</a>
						{/*<div className="right floated">*/}
						{/*	<Link to="/mine/create" className="ui button large circular black approve">Submit</Link>*/}
						{/*</div>*/}
					</div>
					<h1 style={{textAlign: 'center'}}>Buy Licence</h1>
					<div className="flex row wrap center-xs"
					     style={{
						     fontSize: '1.5rem',
					     }}>
						<div className="ui box col-lg-8 col-xs-16">
							<div className="flex row center-xs">
								<img src="/images/server-icon.svg"
								     style={{width: '5rem'}}
								     alt="Kiwi standing on oval"
								/>
							</div>
							<br/>
							<div className="field">
								<label htmlFor="">Number of Servers</label>
								<input type="number"
								       name="number_of_server"
								       defaultValue={100}
								       onChange={() => this.calculatePrice()}
								/>
								<div className="flex row start-xs"
								     style={{padding: '1rem 0 0 0'}}>
									<div className={"ui label basic grey"}>Maximum number of server you want monitor in real
										time.
									</div>
								</div>
							</div>
							<div className="field">
								<label htmlFor="">
									<span>Days</span>
								</label>
								<input type="number"
								       name="number_of_day"
								       defaultValue={14}
								       onChange={() => this.calculatePrice()}
								/>
							</div>
							<div className="field">
								<label htmlFor="">Name</label>
								<input type="text"
								       name="name"
								       placeholder="Mine 1"
								       autoComplete="off"
								/>
							</div>
							<div className="field">
								<label htmlFor="">Summary</label>
								{
									window.login_user.is_free_try
									&&
									<div className="flex row"
									     style={{padding: '1rem 0 0 0'}}>
										<div className="right floated ui label green big">Free Trial 14 Days</div>
									</div>
								}
								<div id="summary"
								     style={{
									     padding: '0.8rem 0 0 0',
									     lineHeight: 1.3,
								     }}>
									-
								</div>
							</div>
							<div className="field">
								<label htmlFor="">Your Address</label>
								<input type="text"
								       name="from_address"
								       autoComplete="off"
								/>
								<input type="hidden"
								       name="to_address"
								       value={"0xb1eb98144b3cbfa9a719f8c8cf6fd88253863385"}
								       autoComplete="off"
								/>
							</div>
							<div className="field">
								<label htmlFor="">To Address</label>
								<p className="ui message info">0xb1eb98144b3cbfa9a719f8c8cf6fd88253863385 <span className="link"
								                                                                                onClick={() => {
									                                                                                try {
										                                                                                navigator.clipboard.writeText('0xb1eb98144b3cbfa9a719f8c8cf6fd88253863385');
										                                                                                window.Global.messageTop({
											                                                                                message: 'Address Copied'
										                                                                                });
									                                                                                } catch (err) {
										                                                                                // console.log('Oops, unable to copy');
									                                                                                }
								                                                                                }}>Copy</span>
									<br/>
									<br/>
									<img src="/images/to_address.png" alt="address" style={{maxHeight: '200px'}}/></p>
							</div>
							<div className="field">
								<label htmlFor="">Upload image (Proof of payment)</label>
								<Form2FileUpload
									input_name="file_uploads"
									removeInputName="remove_file_uploads"
									isCanRemove={false}
									items={[]}
									mode={"view"}
									// url={"https://dev.b49erp.com/api/file/upload?type=upload"}
									url={process.env.REACT_APP_HOST_URL2 + "/api/file/upload?type=upload"}
									uploadButtonName={"Upload"}
									maxFileUpload={5}
									acceptFileTypes={/(\.|\/)(gif|jpe?g|png)$/i}
									showImagePreview={{
										width: '300px',
										height: 'auto',
									}}
									// onUploadDone={(file) => {
									// 	console.log(file);
									// 	return true;
									// }}
									onSuccess={(file) => {
										console.log(file);
									}}
								/>
							</div>
							<div className="field">
								<label htmlFor="">Remark</label>
								<textarea name="licence_history_remark" style={{minHeight: '6rem', height: '6rem'}}/>
							</div>
							<div>
								<div className="field GoogleRecaptcha_root"/>
								<div className="ui error message"/>
								<br/>
								<button type="button" className="ui button approve fluid circular black big">Submit</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}

	renderEdit() {
		if (this.state.isLoading
			|| !this.state.licence_history
		) return null;

		return (
			<div className="secondBackground fluid">
				<form className="ui container padded form"
				      autoComplete="off"
				      ref={(ref) => {
					      this.$form = window.$(ref);
					      this.$form.form2('setup');
					      this.$form.form2('submit', {
						      method: 'PUT',
						      url: process.env.REACT_APP_HOST_URL2 + '/api/v1/licence_history',
						      beforeSent: (object) => {
							      return object;
						      },
						      loadingButton: this.$form.find('.button.approve'),
						      submitButton: this.$form.find('.button.approve'),
						      onSuccess: (response) => {
							      window.Global.messageTop({
								      type: 'positive',
								      message: response.message,
								      delay: 10000,
							      });
							      window.location.href = '/mine';
						      }
					      });

					      setTimeout(() => {
						      this.calculatePrice();
					      }, 400)
				      }}>
					<div className="flex row">
						<a href="/mine" className="ui button large circular basic black">Cancel</a>
						{/*<div className="right floated">*/}
						{/*	<Link to="/mine/create" className="ui button large circular black approve">Submit</Link>*/}
						{/*</div>*/}
					</div>
					<h1 style={{textAlign: 'center'}}>Edit Licence</h1>
					<div className="flex row wrap center-xs"
					     style={{
						     fontSize: '1.5rem',
					     }}>
						<div className="ui box col-lg-8 col-xs-16">
							<div className="flex row center-xs">
								<img src="/images/server-icon.svg"
								     style={{width: '5rem'}}
								     alt="Kiwi standing on oval"
								/>
							</div>
							<br/>
							<div className="field">
								<label htmlFor="">Number of Servers</label>
								<input type="hidden"
								       name="licence_history_id"
								       value={this.state.licence_history.id}
								/>
								<input type="number"
								       name="number_of_server"
								       defaultValue={parseInt(this.state.licence_history.number_of_server)}
								       onChange={() => this.calculatePrice()}
								/>
							</div>
							<div className="field">
								<label htmlFor="">
									<span>Days</span>
								</label>
								<input type="number"
								       name="number_of_day"
								       defaultValue={parseInt(this.state.licence_history.number_of_day)}
								       onChange={() => this.calculatePrice()}
								/>
							</div>
							{/*<div className="field">*/}
							{/*	<label htmlFor="">Name</label>*/}
							{/*	<input type="text"*/}
							{/*	       name="name"*/}
							{/*	       placeholder="Mine 1"*/}
							{/*	       defaultValue={this.state.licence_history.name}*/}
							{/*	       autoComplete="off"*/}
							{/*	/>*/}
							{/*</div>*/}
							<div className="field">
								<label htmlFor="">Start Date</label>
								<div data-form_calendar="datetime"
								     data-input_name="start_date"
								     data-placeholder=""
								     data-initialdate={
									     this.state.licence_history.start_date
										     ?
										     this.state.licence_history.start_date.substring(0, 19)
										     : ''
								     }
								     data-locale="th"
								/>
							</div>
							<div className="field">
								<label htmlFor="">End Date</label>
								<div data-form_calendar="datetime"
								     data-input_name="end_date"
								     data-placeholder=""
								     data-initialdate={
									     this.state.licence_history.end_date
										     ?
										     this.state.licence_history.end_date.substring(0, 19)
										     : ''
								     }
								     data-locale="th"
								/>
							</div>
							<div className="column field required">
								<label>Status</label>
								<select className="ui dropdown"
								        data-form2_setup="true"
								        defaultValue={
									        this.state.licence_history.is_paid === null
										        ? 'null'
										        : this.state.licence_history.is_paid
								        }
								        name="is_paid">
									<option value="null">Waiting</option>
									<option value="true">Approve</option>
									<option value="false">Not Approve</option>
								</select>
							</div>
							<div className="field">
								<label htmlFor="">Summary</label>
								<div id="summary"
								     style={{
									     padding: '0.8rem 0 0 0',
									     lineHeight: 1.3,
								     }}>
									-
								</div>
							</div>
							<div className="field">
								<label htmlFor="">From Address</label>
								<input type="text"
								       name="from_address"
								       defaultValue={this.state.licence_history.from_address}
								       autoComplete="off"
								/>
							</div>
							<div className="field">
								<label htmlFor="">To Address</label>
								<input type="text"
								       name="to_address"
								       defaultValue={this.state.licence_history.to_address}
								       autoComplete="off"
								/>
							</div>
							<div className="field">
								<label htmlFor="">Upload image (Proof of payment)</label>
								<Form2FileUpload
									input_name="file_uploads"
									removeInputName="remove_file_uploads"
									isCanRemove={true}
									items={this.state.licence_history.file_uploads}
									mode={"view"}
									// url={"https://dev.b49erp.com/api/file/upload?type=upload"}
									url={process.env.REACT_APP_HOST_URL2 + "/api/file/upload?type=upload"}
									uploadButtonName={"Upload"}
									maxFileUpload={5}
									acceptFileTypes={/(\.|\/)(gif|jpe?g|png)$/i}
									showImagePreview={{
										width: '300px',
										height: 'auto',
									}}
									// onUploadDone={(file) => {
									// 	console.log(file);
									// 	return true;
									// }}
									onSuccess={(file) => {
										// console.log(file);
									}}
								/>
							</div>
							<div className="field">
								<label htmlFor="">Remark</label>
								<textarea name="remark"
								          defaultValue={this.state.licence_history.remark}
								          style={{minHeight: '6rem', height: '6rem'}}
								/>
							</div>
							<div>
								<div className="field GoogleRecaptcha_root"/>
								<div className="ui error message"/>
								<br/>
								<button type="button" className="ui button approve fluid circular black big">Save</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}
